import { render, staticRenderFns } from "./CarouselPause.vue?vue&type=template&id=307e0030&"
import script from "./CarouselPause.vue?vue&type=script&lang=js&"
export * from "./CarouselPause.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports